import { useLocation } from 'react-router-dom';

import { useIsEnrolledInVariant } from 'hooks/unleash/useIsEnrolledInVariant';
import { FEATURE_LANDING_PAGE_KRATOS } from 'constants/features';
import { redirectLandingPageToKratos } from 'helpers/redirect';

export const RedirectLandingPageToKratos = () => {
  const { isEnrolled: isLandingPageKratos } = useIsEnrolledInVariant(FEATURE_LANDING_PAGE_KRATOS);
  const location = useLocation();

  const { isRedirected, redirectPath } = redirectLandingPageToKratos({
    currentPath: location.pathname,
    isLandingPageKratos,
    search: new URLSearchParams(location.search)
  });

  if (isRedirected && redirectPath && typeof window !== 'undefined') {
    window.location.href = redirectPath;
  }

  return null;
};

RedirectLandingPageToKratos.displayName = 'RedirectLandingPageToKratos';
