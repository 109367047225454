export const redirectLandingPageToKratos = ({
  currentPath,
  isLandingPageKratos,
  search
}: {
  currentPath: string;
  isLandingPageKratos: boolean;
  search?: URLSearchParams;
}) => {
  const redirectedPathnames = ['/c/customer-service'];

  if (isLandingPageKratos && redirectedPathnames.includes(currentPath)) {
    return {
      isRedirected: true,
      redirectPath: search ? `/kratos${currentPath}?${search}` : `/kratos${currentPath}`
    };
  }

  return {
    isRedirected: false
  };
};
